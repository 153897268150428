import * as THREE from 'three'
import { TransformControls } from '@/3DViewer/transform-controls/transform-controls'
import { TransformMode } from '@/3DViewer/transform-controls/types'
import { isMesh, groundObject } from '@/3DViewer/three-utils'
import { Signal } from '@/3DViewer/signal'
import { MeshPhongMaterial } from 'three'
import { defaultMap } from '@/color'

type Pointer = {
  x: number
  y: number
  button: number
}

function isOutOfBounds(pointer: Pointer) {
  return Math.abs(pointer.x) > 1 || Math.abs(pointer.y) > 1
}

/**
 * CanvasObjectControl has allows user to control object by interacting with the canvas object.
 */
export class CanvasObjectControl extends THREE.EventDispatcher<
  | {
      type: 'dragstart' | 'dragend' | 'selected' | 'updated' | 'hoveron' | 'hoveroff'
      object: THREE.Object3D
    }
  | { type: 'deselected'; selected: null }
> {
  private _raycaster: THREE.Raycaster
  private _camera: THREE.PerspectiveCamera
  private _selected: THREE.Mesh | null = null
  private _canvas: HTMLCanvasElement
  private _hovered: THREE.Object3D | null = null
  private _transformControls: TransformControls
  private _signal = Signal.getInstance()

  enabled = true
  objects: THREE.Object3D[] = []

  constructor(camera: THREE.PerspectiveCamera, canvas: HTMLCanvasElement, scene: THREE.Scene) {
    super()
    this._raycaster = new THREE.Raycaster()
    this._camera = camera
    this._canvas = canvas

    this._canvas.addEventListener('pointerup', this.onPointerDown.bind(this))

    this._transformControls = new TransformControls(this._camera, canvas)
    this._transformControls.setMode('translate')

    this._signal.addEventListener('deleted', (event) => {
      if (this._selected && event.object.uuid === this._selected.uuid) {
        this.deselect()
      }
    })

    this._transformControls.addEventListener('dragging-changed', (event) => {
      if (!this._selected) {
        return
      }
      if (event.value) {
        this.dispatchEvent({ type: 'dragstart', object: this._selected })
      } else {
        groundObject(this._selected)
        this.dispatchEvent({ type: 'dragend', object: this._selected })
      }
    })
    scene.add(this._transformControls)
  }

  get transformMode() {
    return this._transformControls.mode
  }

  setTransformMode(mode: TransformMode) {
    this._transformControls.setMode(mode)
  }

  onPointerDown(event: PointerEvent) {
    if (!this.enabled) return

    const pointer = this.getPointer(event)
    if (isOutOfBounds(pointer)) {
      return
    }

    this._raycaster.setFromCamera(pointer, this._camera)
    const objects = this._raycaster.intersectObjects(this.objects, true)

    for (const intersection of objects) {
      const object = intersection.object
      if (!isMesh(object) || !object.visible) {
        continue
      }

      this._transformControls.attach(object)
      this._transformControls.enabled = true
      this._selected = object

      this.dispatchEvent({ type: 'selected', object })
      this._transformControls.enabled = true
      object.material = new MeshPhongMaterial({ color: defaultMap.accent })
      return
    }

    if (this._raycaster.intersectObject(this._transformControls._gizmo).length > 0) {
      return
    }

    this.deselect()
  }

  private deselect() {
    if (!this._selected) {
      return
    }
    this._transformControls.detach()
    this._selected.material = new MeshPhongMaterial({ color: defaultMap.primary })
    this._selected = null

    this.dispatchEvent({ type: 'deselected', selected: this._selected })
  }

  onPointerMove(event: PointerEvent) {
    if (!this.enabled) return

    if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
      const pointer = this.getPointer(event)
      if (isOutOfBounds(pointer)) {
        return
      }
      this._raycaster.setFromCamera(pointer, this._camera)
      const intersections = this._raycaster.intersectObjects(this.objects, true)

      if (intersections.length > 0) {
        const object = intersections[0].object

        if (this._hovered !== object && this._hovered !== null) {
          this.dispatchEvent({ type: 'hoveroff', object: this._hovered })

          this._canvas.style.cursor = 'auto'
          this._hovered = null
        }

        if (this._hovered !== object) {
          this.dispatchEvent({ type: 'hoveron', object: object })

          this._canvas.style.cursor = 'pointer'
          this._hovered = object
        }
      } else {
        if (this._hovered !== null) {
          this.dispatchEvent({ type: 'hoveroff', object: this._hovered })

          this._canvas.style.cursor = 'auto'
          this._hovered = null
        }
      }
    }
  }

  get selected() {
    return this._selected
  }

  getPointer(event: PointerEvent): Pointer {
    const rect = this._canvas.getBoundingClientRect()

    return {
      x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
      y: (-(event.clientY - rect.top) / rect.height) * 2 + 1,
      button: event.button,
    }
  }
}
