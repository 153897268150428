import { Signal } from '@/3DViewer/signal'
import { defined } from '@/utils/defined'
import { isEqual } from 'lodash'
import * as THREE from 'three'

export type ObjectState = {
  position: THREE.Vector3
  rotation: THREE.Euler
  scale: THREE.Vector3
}

export class History {
  private readonly _scene: THREE.Scene
  private _states: Map<string, ObjectState>[] = []
  private _historyOffset = 0
  private _signal = Signal.getInstance()
  private _userObjects = new Map<string, THREE.Mesh>()

  constructor(scene: THREE.Scene, userObject: Map<string, THREE.Mesh>) {
    this._scene = scene
    this._userObjects = userObject
  }

  saveState() {
    if (this._historyOffset !== 0) {
      this._states = this._states.slice(0, -this._historyOffset)
      this._historyOffset = 0
    }

    const state = new Map<string, ObjectState>()

    this._scene.children
      .filter((child) => child.userData.userObject === true)
      .forEach((child) => {
        state.set(child.uuid, {
          position: child.position.clone(),
          rotation: child.rotation.clone(),
          scale: child.scale.clone(),
        })
      })
    if (
      this._states.length === 0 ||
      !this.statesEqual(state, this._states[this._states.length - 1])
    ) {
      this._states.push(state)
    }
  }

  private statesEqual(state1: Map<string, ObjectState>, state2: Map<string, ObjectState>) {
    const keys1 = [...state1.keys()]
    const keys2 = [...state2.keys()]

    if (!isEqual(keys1, keys2)) {
      return false
    }

    for (const [key, object1] of state1) {
      const object2 = defined(
        state2.get(key),
        'Item in state1 does not exist in state2, error in key checking.'
      )
      if (
        !(
          object1.position.equals(object2.position) &&
          object1.rotation.equals(object2.rotation) &&
          object1.scale.equals(object2.scale)
        )
      ) {
        return false
      }
    }
    return true
  }

  clearHistory() {
    this._states = []
    this.saveState()
  }

  goToState(stateIndex: number) {
    if (stateIndex >= this._states.length) {
      return
    }
    const prevState = this._states[stateIndex]
    this._userObjects.forEach((object) => {
      const preObjectState = prevState.get(object.uuid)
      if (!preObjectState) {
        this._signal.dispatchEvent({ type: 'deleted', object: object })
        return
      }
      if (object.userData.deleted) {
        this._signal.dispatchEvent({ type: 'undeleted', object: object })
      }
      object.position.copy(preObjectState.position)
      object.rotation.copy(preObjectState.rotation)
      object.scale.copy(preObjectState.scale)
    })
  }

  undo() {
    if (this._historyOffset + 1 >= this._states.length) {
      return
    }
    this._historyOffset += 1
    this.goToState(this._states.length - 1 - this._historyOffset)
  }

  redo() {
    if (this._historyOffset <= 0) {
      return
    }
    this._historyOffset -= 1
    this.goToState(this._states.length - 1 - this._historyOffset)
  }
}
