import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/studio/plate'
  },
  {
    path: '/studio',
    component: () => import('@/studio/StudioFrame.vue'),
    children: [
      {
        name: 'plate',
        path: 'plate',
        component: () => import('@/studio/StudioPlate.vue'),
      },
      {
        name: 'print-settings',
        path: 'print-settings',
        component: () => import('@/studio/PrintSettings.vue')
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
