export function assertDefined<TValue>(
  value: TValue,
  message: string
): asserts value is NonNullable<TValue> {
  if (value === null || value === undefined) {
    throw Error(message)
  }
}

export function defined<TValue>(value: TValue, message: string) {
  assertDefined(value, message)
  return value
}
