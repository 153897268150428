import { GizmoObject, TransformControlsGizmo } from '@/3DViewer/transform-controls/gizmo'
import { TransformControlsPlane } from '@/3DViewer/transform-controls/plane'
import { Axis, TransformMode, Space } from './types'
import {
  Euler,
  Matrix4,
  Mesh,
  Object3D,
  PerspectiveCamera,
  Quaternion,
  Raycaster,
  Vector3,
} from 'three'

const _raycaster = new Raycaster()

const _tempVector2 = new Vector3()

const _tempVector = new Vector3()
const _tempQuaternion = new Quaternion()
const _tempEuler = new Euler()
const _unitX = new Vector3(1, 0, 0)
const _unitY = new Vector3(0, 1, 0)
const _unitZ = new Vector3(0, 0, 1)
const _alignVector = new Vector3(0, 1, 0)
const _zeroVector = new Vector3(0, 0, 0)
const _lookAtMatrix = new Matrix4()
const _tempQuaternion2 = new Quaternion()
const _identityQuaternion = new Quaternion()

const _unit = {
  X: new Vector3(1, 0, 0),
  Y: new Vector3(0, 1, 0),
  Z: new Vector3(0, 0, 1),
}

type MouseEvent = { type: 'mouseDown' | 'mouseUp'; mode: TransformMode }
type ObjectEvent = { type: 'change' }
type TransformEvent = { type: 'dragging-changed'; value: boolean }

type Pointer = {
  x: number
  y: number
  button: Object3D | number
}

function intersectObjectWithRay(object: Object3D, raycaster: Raycaster, includeInvisible = false) {
  const allIntersections = raycaster.intersectObject(object, true)

  for (let i = 0; i < allIntersections.length; i++) {
    if (allIntersections[i].object.visible || includeInvisible) {
      return allIntersections[i]
    }
  }

  return false
}

export class TransformControls extends Object3D<MouseEvent | ObjectEvent | TransformEvent> {
  private _domElement: HTMLCanvasElement
  _gizmo: TransformControlsGizmo
  private _plane: TransformControlsPlane

  private _axis: Axis | null
  private _cameraQuaternion: Quaternion
  private _eye: Vector3
  private _mode: TransformMode
  private _space: Space
  private _worldPosition: Vector3
  private _worldQuaternion: Quaternion

  camera: PerspectiveCamera
  object?: Object3D
  enabled: boolean
  translationSnap: number | null
  rotationSnap: number | null
  scaleSnap: number | null
  size: number
  dragging: boolean
  showX: boolean
  showY: boolean
  showZ: boolean
  worldPositionStart: Vector3
  worldQuaternionStart: Quaternion
  cameraPosition: Vector3
  pointStart: Vector3
  pointEnd: Vector3
  rotationAxis: Vector3
  rotationAngle: number

  private _offset: Vector3
  private _startNorm: Vector3
  private _endNorm: Vector3
  private _cameraScale: Vector3
  private _parentPosition: Vector3
  private _parentQuaternion: Quaternion
  private _parentQuaternionInv: Quaternion
  private _parentScale: Vector3
  private _worldScaleStart: Vector3
  private _worldQuaternionInv: Quaternion
  private _worldScale: Vector3
  private _positionStart: Vector3
  private _quaternionStart: Quaternion
  private _scaleStart: Vector3

  constructor(camera: PerspectiveCamera, domElement: HTMLCanvasElement) {
    super()

    this.visible = false
    this._domElement = domElement
    this._domElement.style.touchAction = 'none' // disable touch scroll

    // Define properties with getters/setter
    // Setting the defined property will automatically trigger change event
    // Defined properties are passed down to gizmo and plane

    this.camera = camera
    this.object = undefined
    this.enabled = true
    this.translationSnap = null
    this.rotationSnap = null
    this.scaleSnap = null
    this.size = 1
    this.dragging = false
    this.showX = true
    this.showY = true
    this.showZ = true

    // Reusable utility variables

    const worldPosition = new Vector3()
    const worldPositionStart = new Vector3()
    const worldQuaternion = new Quaternion()
    const worldQuaternionStart = new Quaternion()
    const cameraPosition = new Vector3()
    const cameraQuaternion = new Quaternion()
    const pointStart = new Vector3()
    const pointEnd = new Vector3()
    const rotationAxis = new Vector3()
    const rotationAngle = 0
    const eye = new Vector3()

    this._axis = null
    this._cameraQuaternion = cameraQuaternion
    this._eye = eye
    this._mode = 'translate'
    this._space = 'world'
    this._worldPosition = worldPosition
    this._worldQuaternion = worldQuaternion

    const _gizmo = new TransformControlsGizmo()
    this._gizmo = _gizmo
    this.add(_gizmo)

    const _plane = new TransformControlsPlane({
      axis: this._axis,
      cameraQuanterion: this._cameraQuaternion,
      eye: this._eye,
      mode: this._mode,
      space: this._space,
      worldPosition: this._worldPosition,
      worldQuaternion: this._worldQuaternion,
    })

    this._plane = _plane
    this.add(_plane)

    // TODO: remove properties unused in plane and gizmo

    this.worldPositionStart = worldPositionStart
    this.worldQuaternionStart = worldQuaternionStart
    this.cameraPosition = cameraPosition
    this.pointStart = pointStart
    this.pointEnd = pointEnd
    this.rotationAxis = rotationAxis
    this.rotationAngle = rotationAngle

    this._offset = new Vector3()
    this._startNorm = new Vector3()
    this._endNorm = new Vector3()
    this._cameraScale = new Vector3()

    this._parentPosition = new Vector3()
    this._parentQuaternion = new Quaternion()
    this._parentQuaternionInv = new Quaternion()
    this._parentScale = new Vector3()

    this._worldScaleStart = new Vector3()
    this._worldQuaternionInv = new Quaternion()
    this._worldScale = new Vector3()

    this._positionStart = new Vector3()
    this._quaternionStart = new Quaternion()
    this._scaleStart = new Vector3()

    this._domElement.addEventListener('pointerdown', this.onPointerDown.bind(this))
    this._domElement.addEventListener('pointermove', this.onPointerHover.bind(this))
    this._domElement.addEventListener('pointerup', this.onPointerUp.bind(this))
  }

  get axis() {
    return this._axis
  }

  set axis(value: Axis | null) {
    this._axis = value
    this._plane.axis = value
  }

  get cameraQuaternion() {
    return this._cameraQuaternion
  }

  set cameraQuaternion(value: Quaternion) {
    this._cameraQuaternion = value
    this._plane.cameraQuaternion = value
  }

  get eye() {
    return this._eye
  }

  set eye(value: Vector3) {
    this._eye = value
    this._plane.eye = value
  }

  get mode() {
    return this._mode
  }

  set mode(value: TransformMode) {
    this._mode = value
    this._plane.mode = value
  }

  get space() {
    return this._space
  }

  set space(value: Space) {
    this._space = value
    this._plane.space = value
  }

  get worldPosition() {
    return this._worldPosition
  }

  set worldPosition(value: Vector3) {
    this._worldPosition = value
    this._plane.worldPosition = value
  }

  get worldQuaternion() {
    return this._worldQuaternion
  }

  set worldQuaternion(value: Quaternion) {
    this._worldQuaternion = value
    this._plane.worldQuaternion = value
  }

  // updateMatrixWorld  updates key transformation variables
  updateMatrixWorld() {
    if (this.object !== undefined) {
      this.object.updateMatrixWorld()

      if (this.object.parent === null) {
        console.error(
          'TransformControls: The attached 3D object must be a part of the scene graph.'
        )
      } else {
        this.object.parent.matrixWorld.decompose(
          this._parentPosition,
          this._parentQuaternion,
          this._parentScale
        )
      }

      this.object.matrixWorld.decompose(this.worldPosition, this.worldQuaternion, this._worldScale)

      this._parentQuaternionInv.copy(this._parentQuaternion).invert()
      this._worldQuaternionInv.copy(this.worldQuaternion).invert()
    }

    if (this._gizmo) {
      this.updateGizmo()
    }

    this.camera.updateMatrixWorld()
    this.camera.matrixWorld.decompose(this.cameraPosition, this.cameraQuaternion, this._cameraScale)

    this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize()

    super.updateMatrixWorld(true)
    // super.updateMatrixWorld()
  }

  pointerHover(pointer: Pointer) {
    if (this.object === undefined || this.dragging === true) return

    _raycaster.setFromCamera(pointer, this.camera)

    const intersect = intersectObjectWithRay(this._gizmo.picker[this.mode], _raycaster)

    if (intersect) {
      this.axis = intersect.object.name
    } else {
      this.axis = null
    }
  }

  pointerDown(pointer: Pointer) {
    if (this.object === undefined || this.dragging === true || pointer.button !== 0) return

    if (this.axis !== null) {
      _raycaster.setFromCamera(pointer, this.camera)

      const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true)

      if (planeIntersect) {
        this.object.updateMatrixWorld()
        // eslint-disable-next-line no-unused-expressions
        this.object.parent?.updateMatrixWorld()

        this._positionStart.copy(this.object.position)
        this._quaternionStart.copy(this.object.quaternion)
        this._scaleStart.copy(this.object.scale)

        this.object.matrixWorld.decompose(
          this.worldPositionStart,
          this.worldQuaternionStart,
          this._worldScaleStart
        )

        this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart)
      }

      this.dragging = true
      this.dispatchEvent({ type: 'dragging-changed', value: true })
      this.dispatchEvent({ type: 'mouseDown', mode: this.mode })
    }
  }

  pointerMove(pointer: Pointer) {
    const axis = this.axis
    const mode = this.mode
    const object = this.object
    let space = this.space

    if (mode === 'scale') {
      space = 'local'
    } else if (axis === 'E' || axis === 'XYZE' || axis === 'XYZ') {
      space = 'world'
    }

    if (object === undefined || axis === null || this.dragging === false || pointer.button !== -1) {
      return
    }

    _raycaster.setFromCamera(pointer, this.camera)

    const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true)

    if (!planeIntersect) return

    this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart)

    if (mode === 'translate') {
      // Apply translate

      this._offset.copy(this.pointEnd).sub(this.pointStart)

      if (space === 'local' && axis !== 'XYZ') {
        this._offset.applyQuaternion(this._worldQuaternionInv)
      }

      if (axis.indexOf('X') === -1) this._offset.x = 0
      if (axis.indexOf('Y') === -1) this._offset.y = 0
      if (axis.indexOf('Z') === -1) this._offset.z = 0

      if (space === 'local' && axis !== 'XYZ') {
        this._offset.applyQuaternion(this._quaternionStart).divide(this._parentScale)
      } else {
        this._offset.applyQuaternion(this._parentQuaternionInv).divide(this._parentScale)
      }

      object.position.copy(this._offset).add(this._positionStart)

      // Apply translation snap

      if (this.translationSnap) {
        if (space === 'local') {
          object.position.applyQuaternion(_tempQuaternion.copy(this._quaternionStart).invert())

          if (axis.search('X') !== -1) {
            object.position.x =
              Math.round(object.position.x / this.translationSnap) * this.translationSnap
          }

          if (axis.search('Y') !== -1) {
            object.position.y =
              Math.round(object.position.y / this.translationSnap) * this.translationSnap
          }

          if (axis.search('Z') !== -1) {
            object.position.z =
              Math.round(object.position.z / this.translationSnap) * this.translationSnap
          }

          object.position.applyQuaternion(this._quaternionStart)
        }

        if (space === 'world') {
          if (object.parent) {
            object.position.add(_tempVector.setFromMatrixPosition(object.parent.matrixWorld))
          }

          if (axis.search('X') !== -1) {
            object.position.x =
              Math.round(object.position.x / this.translationSnap) * this.translationSnap
          }

          if (axis.search('Y') !== -1) {
            object.position.y =
              Math.round(object.position.y / this.translationSnap) * this.translationSnap
          }

          if (axis.search('Z') !== -1) {
            object.position.z =
              Math.round(object.position.z / this.translationSnap) * this.translationSnap
          }

          if (object.parent) {
            object.position.sub(_tempVector.setFromMatrixPosition(object.parent.matrixWorld))
          }
        }
      }
    } else if (mode === 'scale') {
      if (axis.search('XYZ') !== -1) {
        let d = this.pointEnd.length() / this.pointStart.length()

        if (this.pointEnd.dot(this.pointStart) < 0) d *= -1

        _tempVector2.set(d, d, d)
      } else {
        _tempVector.copy(this.pointStart)
        _tempVector2.copy(this.pointEnd)

        _tempVector.applyQuaternion(this._worldQuaternionInv)
        _tempVector2.applyQuaternion(this._worldQuaternionInv)

        _tempVector2.divide(_tempVector)

        if (axis.search('X') === -1) {
          _tempVector2.x = 1
        }

        if (axis.search('Y') === -1) {
          _tempVector2.y = 1
        }

        if (axis.search('Z') === -1) {
          _tempVector2.z = 1
        }
      }

      // Apply scale

      object.scale.copy(this._scaleStart).multiply(_tempVector2)

      if (this.scaleSnap) {
        if (axis.search('X') !== -1) {
          object.scale.x =
            Math.round(object.scale.x / this.scaleSnap) * this.scaleSnap || this.scaleSnap
        }

        if (axis.search('Y') !== -1) {
          object.scale.y =
            Math.round(object.scale.y / this.scaleSnap) * this.scaleSnap || this.scaleSnap
        }

        if (axis.search('Z') !== -1) {
          object.scale.z =
            Math.round(object.scale.z / this.scaleSnap) * this.scaleSnap || this.scaleSnap
        }
      }
    } else if (mode === 'rotate') {
      this._offset.copy(this.pointEnd).sub(this.pointStart)

      const ROTATION_SPEED =
        20 /
        this.worldPosition.distanceTo(_tempVector.setFromMatrixPosition(this.camera.matrixWorld))

      if (axis === 'E') {
        this.rotationAxis.copy(this.eye)
        this.rotationAngle = this.pointEnd.angleTo(this.pointStart)

        this._startNorm.copy(this.pointStart).normalize()
        this._endNorm.copy(this.pointEnd).normalize()

        this.rotationAngle *= this._endNorm.cross(this._startNorm).dot(this.eye) < 0 ? 1 : -1
      } else if (axis === 'XYZE') {
        this.rotationAxis.copy(this._offset).cross(this.eye).normalize()
        this.rotationAngle =
          this._offset.dot(_tempVector.copy(this.rotationAxis).cross(this.eye)) * ROTATION_SPEED
      } else if (axis === 'X' || axis === 'Y' || axis === 'Z') {
        this.rotationAxis.copy(_unit[axis])

        _tempVector.copy(_unit[axis])

        if (space === 'local') {
          _tempVector.applyQuaternion(this.worldQuaternion)
        }

        this.rotationAngle =
          this._offset.dot(_tempVector.cross(this.eye).normalize()) * ROTATION_SPEED
      }

      // Apply rotation snap

      if (this.rotationSnap) {
        this.rotationAngle = Math.round(this.rotationAngle / this.rotationSnap) * this.rotationSnap
      }

      // Apply rotate
      if (space === 'local' && axis !== 'E' && axis !== 'XYZE') {
        object.quaternion.copy(this._quaternionStart)
        object.quaternion
          .multiply(_tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle))
          .normalize()
      } else {
        this.rotationAxis.applyQuaternion(this._parentQuaternionInv)
        object.quaternion.copy(
          _tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle)
        )
        object.quaternion.multiply(this._quaternionStart).normalize()
      }
    }

    this.dispatchEvent({ type: 'change' })
  }

  pointerUp(pointer: Pointer) {
    if (pointer.button !== 0) return

    if (this.dragging && this.axis !== null) {
      this.dispatchEvent({ type: 'mouseUp', mode: this.mode })
    }

    this.dragging = false
    this.dispatchEvent({ type: 'dragging-changed', value: false })
    this.axis = null
  }

  dispose() {
    this._domElement.removeEventListener('pointerdown', this.onPointerDown)
    this._domElement.removeEventListener('pointermove', this.onPointerHover)
    this._domElement.removeEventListener('pointermove', this.onPointerMove)
    this._domElement.removeEventListener('pointerup', this.onPointerUp)

    this.clear()
  }

  // Set current object
  attach(object: Mesh) {
    if (this.object === object) {
      return this
    }

    if (this.object !== undefined) {
      this.detach()
    }

    this.object = object
    this.visible = true

    object.geometry.computeBoundingSphere()

    return this
  }

  // Detatch from object
  detach() {
    this.object = undefined
    this.visible = false
    this.axis = null

    return this
  }

  getRaycaster() {
    return _raycaster
  }

  // TODO: deprecate

  getMode() {
    return this.mode
  }

  setMode(mode: TransformMode) {
    this.mode = mode
    this._plane.mode = mode
  }

  setTranslationSnap(translationSnap: number | null) {
    this.translationSnap = translationSnap
  }

  setRotationSnap(rotationSnap: number | null) {
    this.rotationSnap = rotationSnap
  }

  setScaleSnap(scaleSnap: number | null) {
    this.scaleSnap = scaleSnap
  }

  setSize(size: number) {
    this.size = size
  }

  setSpace(space: Space) {
    this.space = space
  }

  getPointer(event: PointerEvent): Pointer {
    if (this._domElement.ownerDocument.pointerLockElement) {
      return {
        x: 0,
        y: 0,
        button: event.button,
      }
    } else {
      const rect = this._domElement.getBoundingClientRect()

      return {
        x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
        y: (-(event.clientY - rect.top) / rect.height) * 2 + 1,
        button: event.button,
      }
    }
  }

  onPointerHover(event: PointerEvent) {
    if (!this.enabled) return

    switch (event.pointerType) {
      case 'mouse':
      case 'pen':
        this.pointerHover(this.getPointer(event))
        break
    }
  }

  onPointerDown(event: PointerEvent) {
    if (!this.enabled) return

    this._domElement.setPointerCapture(event.pointerId)

    this._domElement.addEventListener('pointermove', this.onPointerMove.bind(this))

    this.pointerHover(this.getPointer(event))
    this.pointerDown(this.getPointer(event))
  }

  onPointerMove(event: PointerEvent) {
    if (!this.enabled) return

    this.pointerMove(this.getPointer(event))
  }

  onPointerUp(event: PointerEvent) {
    if (!this.enabled) return

    this._domElement.releasePointerCapture(event.pointerId)

    this._domElement.removeEventListener('pointermove', this.onPointerMove)

    this.pointerUp(this.getPointer(event))
  }

  updateGizmo() {
    const space = this.mode === 'scale' ? 'local' : this.space // scale always oriented to local rotation

    const quaternion = space === 'local' ? this.worldQuaternion : _identityQuaternion

    // Show only gizmos for current transform mode

    this._gizmo.gizmo.translate.visible = this.mode === 'translate'
    this._gizmo.gizmo.rotate.visible = this.mode === 'rotate'
    this._gizmo.gizmo.scale.visible = this.mode === 'scale'

    this._gizmo.helper.translate.visible = this.mode === 'translate'
    this._gizmo.helper.rotate.visible = this.mode === 'rotate'
    this._gizmo.helper.scale.visible = this.mode === 'scale'

    let handles: GizmoObject[] = []
    handles = handles.concat(this._gizmo.picker[this.mode].children)
    handles = handles.concat(this._gizmo.gizmo[this.mode].children)
    handles = handles.concat(this._gizmo.helper[this.mode].children)

    for (let i = 0; i < handles.length; i++) {
      const handle = handles[i]

      // hide aligned to camera

      handle.visible = true
      handle.rotation.set(0, 0, 0)
      handle.position.copy(this.worldPosition)

      const factor =
        this.worldPosition.distanceTo(this.cameraPosition) *
        Math.min((1.9 * Math.tan((Math.PI * this.camera.fov) / 360)) / this.camera.zoom, 7)

      handle.scale.set(1, 1, 1).multiplyScalar((factor * this.size) / 4)

      // TODO: simplify helpers and consider decoupling from gizmo

      if (handle.tag === 'helper') {
        handle.visible = false

        if (handle.name === 'AXIS') {
          handle.position.copy(this.worldPositionStart)
          handle.visible = !!this.axis

          if (this.axis === 'X') {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, 0, 0))
            handle.quaternion.copy(quaternion).multiply(_tempQuaternion)

            if (
              Math.abs(_alignVector.copy(_unitX).applyQuaternion(quaternion).dot(this.eye)) > 0.9
            ) {
              handle.visible = false
            }
          }

          if (this.axis === 'Y') {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, 0, Math.PI / 2))
            handle.quaternion.copy(quaternion).multiply(_tempQuaternion)

            if (
              Math.abs(_alignVector.copy(_unitY).applyQuaternion(quaternion).dot(this.eye)) > 0.9
            ) {
              handle.visible = false
            }
          }

          if (this.axis === 'Z') {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, Math.PI / 2, 0))
            handle.quaternion.copy(quaternion).multiply(_tempQuaternion)

            if (
              Math.abs(_alignVector.copy(_unitZ).applyQuaternion(quaternion).dot(this.eye)) > 0.9
            ) {
              handle.visible = false
            }
          }

          if (this.axis === 'XYZE') {
            _tempQuaternion.setFromEuler(_tempEuler.set(0, Math.PI / 2, 0))
            _alignVector.copy(this.rotationAxis)
            handle.quaternion.setFromRotationMatrix(
              _lookAtMatrix.lookAt(_zeroVector, _alignVector, _unitY)
            )
            handle.quaternion.multiply(_tempQuaternion)
            handle.visible = this.dragging
          }

          if (this.axis === 'E') {
            handle.visible = false
          }
        } else if (handle.name === 'START') {
          handle.position.copy(this.worldPositionStart)
          handle.visible = this.dragging
        } else if (handle.name === 'END') {
          handle.position.copy(this.worldPosition)
          handle.visible = this.dragging
        } else if (handle.name === 'DELTA') {
          handle.position.copy(this.worldPositionStart)
          handle.quaternion.copy(this.worldQuaternionStart)
          _tempVector
            .set(1e-10, 1e-10, 1e-10)
            .add(this.worldPositionStart)
            .sub(this.worldPosition)
            .multiplyScalar(-1)
          _tempVector.applyQuaternion(this.worldQuaternionStart.clone().invert())
          handle.scale.copy(_tempVector)
          handle.visible = this.dragging
        } else {
          handle.quaternion.copy(quaternion)

          if (this.dragging) {
            handle.position.copy(this.worldPositionStart)
          } else {
            handle.position.copy(this.worldPosition)
          }

          if (this.axis) {
            handle.visible = this.axis.search(handle.name) !== -1
          }
        }

        // If updating helper, skip rest of the loop
        continue
      }

      // Align handles to current local or world rotation

      handle.quaternion.copy(quaternion)

      if (this.mode === 'translate' || this.mode === 'scale') {
        // Hide translate and scale axis facing the camera

        const AXIS_HIDE_TRESHOLD = 0.99
        const PLANE_HIDE_TRESHOLD = 0.2

        if (handle.name === 'X') {
          if (
            Math.abs(_alignVector.copy(_unitX).applyQuaternion(quaternion).dot(this.eye)) >
            AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10)
            handle.visible = false
          }
        }

        if (handle.name === 'Y') {
          if (
            Math.abs(_alignVector.copy(_unitY).applyQuaternion(quaternion).dot(this.eye)) >
            AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10)
            handle.visible = false
          }
        }

        if (handle.name === 'Z') {
          if (
            Math.abs(_alignVector.copy(_unitZ).applyQuaternion(quaternion).dot(this.eye)) >
            AXIS_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10)
            handle.visible = false
          }
        }

        if (handle.name === 'XY') {
          if (
            Math.abs(_alignVector.copy(_unitZ).applyQuaternion(quaternion).dot(this.eye)) <
            PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10)
            handle.visible = false
          }
        }

        if (handle.name === 'YZ') {
          if (
            Math.abs(_alignVector.copy(_unitX).applyQuaternion(quaternion).dot(this.eye)) <
            PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10)
            handle.visible = false
          }
        }

        if (handle.name === 'XZ') {
          if (
            Math.abs(_alignVector.copy(_unitY).applyQuaternion(quaternion).dot(this.eye)) <
            PLANE_HIDE_TRESHOLD
          ) {
            handle.scale.set(1e-10, 1e-10, 1e-10)
            handle.visible = false
          }
        }
      } else if (this.mode === 'rotate') {
        // Align handles to current local or world rotation

        _tempQuaternion2.copy(quaternion)
        _alignVector.copy(this.eye).applyQuaternion(_tempQuaternion.copy(quaternion).invert())

        if (handle.name.search('E') !== -1) {
          handle.quaternion.setFromRotationMatrix(
            _lookAtMatrix.lookAt(this.eye, _zeroVector, _unitY)
          )
        }

        if (handle.name === 'X') {
          _tempQuaternion.setFromAxisAngle(_unitX, Math.atan2(-_alignVector.y, _alignVector.z))
          _tempQuaternion.multiplyQuaternions(_tempQuaternion2, _tempQuaternion)
          handle.quaternion.copy(_tempQuaternion)
        }

        if (handle.name === 'Y') {
          _tempQuaternion.setFromAxisAngle(_unitY, Math.atan2(_alignVector.x, _alignVector.z))
          _tempQuaternion.multiplyQuaternions(_tempQuaternion2, _tempQuaternion)
          handle.quaternion.copy(_tempQuaternion)
        }

        if (handle.name === 'Z') {
          _tempQuaternion.setFromAxisAngle(_unitZ, Math.atan2(_alignVector.y, _alignVector.x))
          _tempQuaternion.multiplyQuaternions(_tempQuaternion2, _tempQuaternion)
          handle.quaternion.copy(_tempQuaternion)
        }
      }

      // Hide disabled axes
      handle.visible = handle.visible && (handle.name.indexOf('X') === -1 || this.showX)
      handle.visible = handle.visible && (handle.name.indexOf('Y') === -1 || this.showY)
      handle.visible = handle.visible && (handle.name.indexOf('Z') === -1 || this.showZ)
      handle.visible =
        handle.visible &&
        (handle.name.indexOf('E') === -1 || (this.showX && this.showY && this.showZ))

      // highlight selected axis

      // Handling materials mannually like this may cause a memory leak. Might be work looking into.
      handle.material.defaultColor = handle.material.defaultColor || handle.material.color.clone()
      handle.material.defaultOpacity = handle.material.defaultOpacity || handle.material.opacity

      handle.material.color.copy(handle.material.defaultColor)
      handle.material.opacity = handle.material.defaultOpacity

      if (this.enabled && this.axis) {
        if (handle.name === this.axis) {
          handle.material.color.setHex(0xffff00)
          handle.material.opacity = 1.0
        } else if (
          this.axis.split('').some(function (a) {
            return handle.name === a
          })
        ) {
          handle.material.color.setHex(0xffff00)
          handle.material.opacity = 1.0
        }
      }
    }
  }
}
