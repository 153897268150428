import { CanvasObjectControl } from '@/3DViewer/canvas-object-control'
import { Signal } from '@/3DViewer/signal'
import { TransformMode } from '@/3DViewer/transform-controls/types'
import { Object3D } from '@/3DViewer/types'
import { PubSub } from '@/utils/pub-sub'
import * as THREE from 'three'

function threeObjecToObject(object: THREE.Object3D): Object3D {
  return {
    position: {
      x: object.position.x,
      y: object.position.y,
      z: object.position.z,
    },
    rotation: {
      x: object.rotation.x,
      y: object.rotation.y,
      z: object.rotation.z,
    },
  }
}

export class ObjectControl extends PubSub<
  { type: 'selected' | 'updated'; object: Object3D } | { type: 'deselected' }
> {
  private _canvasObjectControl: CanvasObjectControl
  private _signal = Signal.getInstance()

  constructor(canvasObjectControl: CanvasObjectControl) {
    super()
    this._canvasObjectControl = canvasObjectControl

    this._canvasObjectControl.addEventListener('selected', ({ object }) => {
      this.dispatchEvent({ type: 'selected', object: threeObjecToObject(object) })
    })

    this._canvasObjectControl.addEventListener('updated', ({ object }) => {
      this.dispatchEvent({ type: 'updated', object: threeObjecToObject(object) })
    })

    this._canvasObjectControl.addEventListener('deselected', () => {
      this.dispatchEvent({ type: 'deselected' })
    })
  }

  updateSelectedObject(object: Object3D) {
    if (this._canvasObjectControl.selected) {
      const newPosition = new THREE.Vector3(object.position.x, object.position.y, object.position.z)
      const newRotation = new THREE.Euler(object.rotation.x, object.rotation.y, object.rotation.z)

      this._canvasObjectControl.selected.position.copy(newPosition)
      this._canvasObjectControl.selected.rotation.copy(newRotation)
    }
  }

  deleteSelected() {
    if (this.selected) {
      this._signal.dispatchEvent({ type: 'deleted', object: this.selected })
    }
  }

  get transformMode() {
    return this._canvasObjectControl.transformMode
  }

  setTransformMode(mode: TransformMode) {
    this._canvasObjectControl.setTransformMode(mode)
  }

  get selected() {
    return this._canvasObjectControl.selected
  }
}
