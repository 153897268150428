import * as THREE from 'three'
import { OrbitControls } from '@/3DViewer/orbit-controls'
import { STLLoader } from '@/3DViewer/stl-importer'
import { STLExporter } from '@/3DViewer/stl-exporter'
import { CanvasObjectControl } from '@/3DViewer/canvas-object-control'
import { ObjectControl } from '@/3DViewer/object-control'
import { groundObject } from '@/3DViewer/three-utils'
import { throttle } from 'lodash'
import { History } from '@/3DViewer/history'
import { Signal } from '@/3DViewer/signal'
import { BufferGeometry, Material } from 'three'

export class Scene {
  viewBoundingBoxes = false

  private readonly _scene: THREE.Scene
  private readonly _renderer: THREE.WebGLRenderer
  private readonly _camera: THREE.PerspectiveCamera
  private readonly _orbitControls: OrbitControls
  private readonly _canvsObjectControl: CanvasObjectControl
  private readonly _history: History
  readonly objectControl: ObjectControl

  private _objectColour: THREE.Color
  private _userMeshs = new Map<string, THREE.Mesh<BufferGeometry, Material>>()
  private _mounted = false
  private _boundingBoxes: THREE.BoxHelper[] = []
  private _signal = Signal.getInstance()

  constructor({ objectColor, backgroundColor }: { objectColor: number; backgroundColor: number }) {
    this._scene = new THREE.Scene()
    this._scene.background = new THREE.Color(backgroundColor)
    this._renderer = new THREE.WebGLRenderer()
    const canvas = this._renderer.domElement
    this._objectColour = new THREE.Color(objectColor)
    this._history = new History(this._scene, this._userMeshs)

    this._camera = new THREE.PerspectiveCamera(50, canvas.width / canvas.height, 0.1, 1000)
    this._orbitControls = new OrbitControls(this._camera, canvas)
    this._camera.position.set(-200, -200, 200)
    this._orbitControls.update()
    this._canvsObjectControl = new CanvasObjectControl(this._camera, canvas, this._scene)
    this.objectControl = new ObjectControl(this._canvsObjectControl)

    canvas.style.display = 'inline'
    window.addEventListener(
      'resize',
      throttle(
        () =>
          this.setSize(
            this._renderer.domElement.clientWidth,
            this._renderer.domElement.clientHeight
          ),
        100
      )
    )
    // Event listener for when an object is deleted.
    this._signal.addEventListener('deleted', (event) => {
      event.object.visible = false
      event.object.userData.deleted = true
      this._scene.remove(event.object)
    })

    // Event listener for when an object is "un-deleted", such as undoing a delete.
    this._signal.addEventListener('undeleted', (event) => {
      event.object.visible = true
      event.object.userData.deleted = false
      this._scene.add(event.object)
    })

    this._canvsObjectControl.addEventListener('dragend', () => {
      this._history.saveState()
    })

    this._canvsObjectControl.addEventListener('dragstart', () => {
      this._orbitControls.enabled = false
    })

    this._canvsObjectControl.addEventListener('dragend', () => {
      this._orbitControls.enabled = true
    })
  }

  async initialize() {
    await this.addBed()
    this.addLighting()
    this._history.saveState()
  }

  deleteSelected() {
    this.objectControl.deleteSelected()
    this._history.saveState()
  }

  private startAnimation() {
    const animate = () => {
      // Stop animation if the scene is no longer mounted, user cannot see the beautiful renders.
      if (!this._mounted) {
        return
      }

      requestAnimationFrame(animate)
      this._orbitControls.update()
      this._renderer.render(this._scene, this._camera)

      this._boundingBoxes.forEach((helper) => {
        helper.visible = this.viewBoundingBoxes
        helper.update()
      })
    }

    animate()
  }

  private setSize(width: number, height: number) {
    this._renderer.setSize(width, height, false)
    this._camera.aspect = width / height
    this._camera.updateProjectionMatrix()
  }

  /**
   * Do not mount directly, use SceneRenderer
   */
  mount(element: HTMLElement) {
    if (this._mounted) {
      throw new Error('Scene may be only be mounted once to one dom element.')
    }
    element.appendChild(this._renderer.domElement)
    this._renderer.domElement.style.width = '100%'
    this._renderer.domElement.style.height = '100%'

    this.setSize(this._renderer.domElement.clientWidth, this._renderer.domElement.clientHeight)
    this._mounted = true
    this.startAnimation()
  }

  unmount() {
    this._mounted = false
  }

  async importStl(stlData: ArrayBuffer) {
    const stlImporter = new STLLoader()
    const geometry = stlImporter.parse(stlData)
    const material = new THREE.MeshPhongMaterial({ color: this._objectColour })
    const mesh = new THREE.Mesh(geometry, material)
    this._scene.add(mesh)
    this._canvsObjectControl.objects.push(mesh)
    this._userMeshs.set(mesh.uuid, mesh)

    const boundingBox = new THREE.BoxHelper(mesh)
    boundingBox.visible = this.viewBoundingBoxes
    this._scene.add(boundingBox)
    this._boundingBoxes.push(boundingBox)

    mesh.userData.userObject = true
    groundObject(mesh)

    this._history.saveState()
  }

  exportToStl() {
    return STLExporter.parse(this._scene, { onlyInclude: [...this._userMeshs.keys()] })
  }

  undo() {
    this._history.undo()
  }

  redo() {
    this._history.redo()
  }

  private async addBed() {
    const planeSize = 180
    const grid = new THREE.GridHelper(planeSize, 10)
    grid.rotation.x = Math.PI / 2
    this._scene.add(grid)

    const axesHelper = new THREE.AxesHelper(20)
    this._scene.add(axesHelper)

    axesHelper.position.x = -planeSize / 2
    axesHelper.position.y = -planeSize / 2

    return grid
  }

  private addLighting = () => {
    const skyColor = 0xffffff // light blue
    const groundColor = 0xbdbdbd // brownish orange
    const intensity = 1
    const light = new THREE.HemisphereLight(skyColor, groundColor, intensity)
    light.up.set(0, 0, 1)
    this._scene.add(light)
  }
}
