import * as THREE from 'three'

export function isMesh(object: THREE.Object3D | THREE.Mesh): object is THREE.Mesh {
  return 'isMesh' in object && object.isMesh
}

export function isSkinnedMesh(
  object: THREE.Object3D | THREE.SkinnedMesh
): object is THREE.SkinnedMesh {
  return 'isSkinnedMesh' in object && object.isSkinnedMesh
}

export function getGeometryBottom(object: THREE.Object3D) {
  const box = new THREE.Box3().setFromObject(object)
  const center = new THREE.Vector3(0, 0, 0)

  const size = new THREE.Vector3(0, 0, 0)
  box.getCenter(center)
  box.getSize(size)

  return size.z / 2
}

export function groundObject(object: THREE.Object3D) {
  object.position.z = getGeometryBottom(object)
  return object
}
