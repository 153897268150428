import { PubSub } from '@/utils/pub-sub'
import * as THREE from 'three'

type Event =
  | { type: 'deleted'; object: THREE.Object3D }
  | { type: 'undeleted'; object: THREE.Object3D }

export class Signal extends PubSub<Event> {
  private static instance: Signal

  public static getInstance(): Signal {
    if (!Signal.instance) {
      Signal.instance = new Signal()
    }

    return Signal.instance
  }
}
