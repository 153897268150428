/**
 * Usage:
 *  const exporter = new STLExporter();
 *
 *  // second argument is a list of options
 *  const data = exporter.parse( mesh, { binary: true } );
 *
 */
import { isMesh, isSkinnedMesh } from '@/3DViewer/three-utils'
import * as THREE from 'three'
import { Object3D } from 'three'

export class STLExporter {
  static parse(
    scene: THREE.Scene,
    options: { onlyInclude?: string[] } = { onlyInclude: undefined }
  ) {
    const objects: { object3d: Object3D; geometry: THREE.BufferGeometry }[] = []
    let triangles = 0
    scene.traverse((object: THREE.Object3D) => {
      if (options.onlyInclude !== undefined && !options.onlyInclude.includes(object.uuid)) {
        return
      }
      if (isMesh(object)) {
        const geometry = object.geometry

        if (geometry.isBufferGeometry !== true) {
          throw new Error('THREE.STLExporter: Geometry is not of type THREE.BufferGeometry.')
        }

        const index = geometry.index
        const positionAttribute = geometry.getAttribute('position')
        triangles += index !== null ? index.count / 3 : positionAttribute.count / 3
        objects.push({
          object3d: object,
          geometry: geometry,
        })
      }
    })
    let offset = 80 // skip header

    const bufferLength = triangles * 2 + triangles * 3 * 4 * 4 + 80 + 4
    const arrayBuffer = new ArrayBuffer(bufferLength)
    const outputDataView = new DataView(arrayBuffer)
    outputDataView.setUint32(offset, triangles, true)
    offset += 4

    const vA = new THREE.Vector3()
    const vB = new THREE.Vector3()
    const vC = new THREE.Vector3()
    const cb = new THREE.Vector3()
    const ab = new THREE.Vector3()
    const normal = new THREE.Vector3()

    for (let i = 0, il = objects.length; i < il; i++) {
      const object = objects[i].object3d
      const geometry = objects[i].geometry
      const index = geometry.index
      const positionAttribute = geometry.getAttribute('position')

      if (index !== null) {
        // indexed geometry
        for (let j = 0; j < index.count; j += 3) {
          const a = index.getX(j + 0)
          const b = index.getX(j + 1)
          const c = index.getX(j + 2)
          writeFace(a, b, c, positionAttribute, object)
        }
      } else {
        // non-indexed geometry
        for (let j = 0; j < positionAttribute.count; j += 3) {
          const a = j + 0
          const b = j + 1
          const c = j + 2
          writeFace(a, b, c, positionAttribute, object)
        }
      }
    }

    return outputDataView

    function writeFace(
      a: number,
      b: number,
      c: number,
      positionAttribute: THREE.BufferAttribute | THREE.InterleavedBufferAttribute,
      object: THREE.Object3D
    ) {
      vA.fromBufferAttribute(positionAttribute, a)
      vB.fromBufferAttribute(positionAttribute, b)
      vC.fromBufferAttribute(positionAttribute, c)

      if (isSkinnedMesh(object)) {
        object.boneTransform(a, vA)
        object.boneTransform(b, vB)
        object.boneTransform(c, vC)
      }

      vA.applyMatrix4(object.matrixWorld)
      vB.applyMatrix4(object.matrixWorld)
      vC.applyMatrix4(object.matrixWorld)
      writeNormal(vA, vB, vC)
      writeVertex(vA)
      writeVertex(vB)
      writeVertex(vC)

      outputDataView.setUint16(offset, 0, true)
      offset += 2
    }

    function writeNormal(vA: THREE.Vector3, vB: THREE.Vector3, vC: THREE.Vector3) {
      cb.subVectors(vC, vB)
      ab.subVectors(vA, vB)
      cb.cross(ab).normalize()
      normal.copy(cb).normalize()

      outputDataView.setFloat32(offset, normal.x, true)
      offset += 4
      outputDataView.setFloat32(offset, normal.y, true)
      offset += 4
      outputDataView.setFloat32(offset, normal.z, true)
      offset += 4
    }

    function writeVertex(vertex: THREE.Vector3) {
      outputDataView.setFloat32(offset, vertex.x, true)
      offset += 4
      outputDataView.setFloat32(offset, vertex.y, true)
      offset += 4
      outputDataView.setFloat32(offset, vertex.z, true)
      offset += 4
    }
  }
}
