import { Scene } from '@/3DViewer/scene'
import { defaultMap } from '@/color'
import { inject, InjectionKey, provide } from 'vue'

const SCENE_KEY = Symbol('Injection key for the scene.') as InjectionKey<Scene>

export function useScene() {
  const scene = new Scene({ objectColor: defaultMap.primary, backgroundColor: defaultMap.neutral3 })
  scene.initialize()

  provide(SCENE_KEY, scene)
}

export function getScene() {
  return inject(SCENE_KEY)
}
