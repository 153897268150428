import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

type State = {}

export const key: InjectionKey<Store<State>> = Symbol('Vue store injection key')

export default createStore<State>({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
})

export function useStore() {
  return baseUseStore(key)
}
